<template>
  <div class="text-justify">
    <p align="center"><strong>F.A.Q</strong></p>
    <p><strong>Syn Pitarn</strong></p>
    <ul>
      <li>
        <strong>Who is Syn Pitarn</strong>
        <ul>
          <li>
            <em
              >Syn Pitarn is a Thai financial services group that focuses on financial inclusion for low income workers
              especially migrant workers in Thailand.
            </em>
          </li>
          <li><em>Our core service is providing loans.</em></li>
          <li>
            <em
              >We have started operations in Pathumthani and will expand our operation to cover other provinces in the
              coming months</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>License / Is it legal?</strong>
        <ul>
          <li>
            <em>Syn Pitarn is licensed and regulated by the Ministry of Finance as a Pico Finance company.</em>
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li><strong>Benefit in borrowing from Syn Pitarn</strong></li>
    </ul>
    <p>&uuml;&nbsp; <em>We are regulated</em></p>
    <p>&uuml;&nbsp; <em>We are transparent. </em></p>
    <p>&uuml;&nbsp; <em>Everything is in Myanmar language</em></p>
    <p>
      &uuml;&nbsp;
      <em>Our interest rate is set by the government at 3% a month</em>
    </p>
    <p>
      &uuml;&nbsp;
      <em
        >If you have a good credit history we will consider lowering this rate
        <span> @click="scroll('lower-rate')" class="text-primary cursor-pointer">[click to relevant FAQ]</span></em
      >
    </p>
    <p>&uuml;&nbsp; <em>There are no hidden charges or fees</em></p>
    <p>
      &uuml;&nbsp;
      <em
        >We are convenient&hellip;. After only one visit to our branch most of our services can be accessed online at
        any time.</em
      >
    </p>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Do I have to pay anything before getting a loan</strong>
        <ul>
          <li>
            <em>No. You do not need to pay for anything before getting a loan</em>
          </li>
          <li><em>There are no hidden charges</em></li>
          <li>
            <em
              >We do not use agents to talk to clients so do not pay any fee to any person who says they can help you
              get a loan from Syn Pitarn</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><strong>&nbsp;</strong></p>
    <ul>
      <li><strong>Syn Pitarn is designed to make your life easier</strong></li>
    </ul>
    <p><em>Congratulations. </em></p>
    <ul>
      <li>
        <em
          >By using Syn Pitarn&rsquo;s services you are benefiting from financial technology. You will use your mobile
          phone to communicate with us at a convenient time.</em
        >
      </li>
      <li>
        <em
          >You can access information on your loan status and your repayment schedule or you can request a new
          loan&hellip; all on your mobile phone.</em
        >
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Opening hours</strong>
        <ul>
          <li><em>We are opened in the evenings as well as weekends</em></li>
        </ul>
      </li>
    </ul>
    <p>&nbsp;</p>
    <ul>
      <li>
        <strong>How can I contact Syn Pitarn</strong>
        <ul>
          <li>
            <em>Please contact us through [messenger] </em>
            <span class="text-primary cursor-pointer" @click="goToMessenger"> [click here]</span>
          </li>
          <li>
            <em
              >Or visit our branch at
              <span class="text-primary cursor-pointer" @click="goToBranch"> [click to go to address]</span></em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <p><strong>Loans</strong></p>
    <ul>
      <li>
        <strong>To be eligible for a loan you must;</strong>
        <ul>
          <li><em>Live or work in Pathumthani</em></li>
          <li><em>Be at least 20 years old</em></li>
          <li>
            <em>have worked in Thailand for at least 2 years and at the same company in Thailand for 1 years</em>
          </li>
          <li>
            <em
              >have a valid work permit (pink card/MOU) that has at least 3 months left after the last repayment date of
              your loan</em
            >
          </li>
          <li><em>have a Thai bank account</em></li>
          <li><em>Have a Facebook Messenger, Line or Viber account</em></li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>How long does the process take</strong>
        <ul>
          <li>
            <em
              >Once you have submitted the loan application form through your mobile phone, you will be asked to select
              a time for a video call with our Myanmar loan officer. This is typically 3-5 days after submission.</em
            >
          </li>
          <li>
            <em
              >After your video call with our loan officer, if the loan is approved, you will need to visit our branch
              for verification. You only have to this one time.</em
            >
          </li>
          <li><em>&nbsp;We will transfer the loan within the same day.</em></li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Do I have to visit a branch</strong>
        <ul>
          <li>
            <em>Yes because we need to check that you are the same person as your documents.</em>
          </li>
          <li>
            <em
              >You only have to visit a branch once for verification. After that everything is done through your mobile
              phone.</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>How much can I borrow</strong>
        <ul>
          <li>
            <em>Your first loan with Syn Pitarn will be a maximum of Baht 5,000.</em>
          </li>
          <li>
            <em>However, if you have a good credit history we can consider increasing this.</em>
          </li>
          <li><em>The maximum loan from Syn Pitarn is Baht 50,000</em></li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>What is Syn Pitarn&rsquo;</strong><strong>s interest rate</strong>
        <ul>
          <li>
            <em>Our interest rate is set by the government at 3% per month</em>
          </li>
          <li>
            <em
              >Our interest is calculated on a declining rate basis which means interest is calculate on the amount of
              loan principle outstanding</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>What is a &ldquo;Credit History&rdquo;</strong>
        <ul>
          <li>
            <em>Credit history is a record of how you have repaid your loans.</em>
          </li>
          <li>
            <em
              >By consistently repaying the scheduled amount on the agreed date you will have a good credit history.</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><strong>&nbsp;</strong></p>
    <p><strong>&nbsp;</strong></p>
    <ul id="lower-rate">
      <li>
        <strong>Can I get a lower interest rate</strong>
        <ul>
          <li>
            <em
              >Yes. If you have a good credit history after having 2 loans with Syn Pitarn we will consider reducing
              your interest rate.</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p>
      <strong><em>&nbsp;</em></strong>
    </p>
    <ul>
      <li>
        <strong>How long is the loan term</strong>
        <ul>
          <li>
            <em>Your first loan will be for 2-3 months</em
            ><a href="file:///E:/Synpitarn/data/SPT%20FAQ%20FINAL%20draft%2030-5-22_Eng.docx#_msocom_1">[AH1]</a
            >&nbsp;<em> repayable in 4 instalments every 2 weeks</em>
          </li>
          <li>
            <em
              >After your first loan and if you have a good credit history, we are happy to discuss larger and longer
              term loans which can go up to 6 months</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><strong>&nbsp;</strong></p>
    <ul>
      <li>
        <strong>How often do I have to make a repayment</strong>
        <ul>
          <li>
            <em>Normally every 2 weeks but for proven good credit customers we can consider monthly repayments</em>
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Is there a penalty for late payment</strong>
        <ul>
          <li>
            <em
              >If you are late making a repayment by 5 days we reserve the right to charge an additional Baht 50 per
              month to cover our extra work</em
            >
          </li>
          <li>
            <em>Late repayment will also affect your credit history and your future ability to borrow money</em>
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Can I have more than 1 loan</strong>
        <ul>
          <li>
            <em
              >No. you can only have 1 loan at any one time. However,r after you have repaid your loan you can apply for
              a new one.</em
            >
          </li>
          <li>
            <em
              >For your next loan you will not need to visit our branch unless your last loan was more than 6 months
              ago.</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Where can I collect the loan</strong>
        <ul>
          <li>
            <em>We always transfer your loans directly into your bank account</em>
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Can the loan be transferred into bank account in a different name from my NRC / work permit?</strong>
        <ul>
          <li>
            <em>No. </em
            ><em>We will only transfer your money to bank account with the same name as your identity documents. </em>
          </li>
        </ul>
      </li>
    </ul>
    <p><strong>Loan application process</strong></p>
    <ul>
      <li>
        <strong>How do I apply for a loan</strong>
        <ul>
          <li>
            <em
              >Please start by filling in the loan application form
              <span class="text-primary cursor-pointer" @click="goToRegister">[click here]</span>.</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li><strong>What documents do I need to submit</strong></li>
    </ul>
    <p><em>Only 4 documents need to be uploaded from your mobile phone </em></p>
    <ul>
      <li>
        <em>A selfie. A clear picture, no hat or sunglasses, preferably against a white background</em>
      </li>
      <li><em>Your Thai work permit or pink card (both sides)</em></li>
      <li><em>Your Myanmar NRIC or passport</em></li>
      <li>
        <em
          >A picture of your most recent payslip or a copy of your bank statement or bank book showing your most recent
          salary payment</em
        >
      </li>
    </ul>
    <p>
      <em>We recommend that you prepare these documents first before completing the application form</em>
    </p>
    <ul>
      <li>
        <strong>Do I need to have collateral to borrow from Syn Pitarn</strong>
        <ul>
          <li><em>We do not need any collateral for a loan</em></li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>If I have collateral, can I obtain loan easier or can receive more loan amount? </strong>
        <ul>
          <li>
            <em>In the future we will be offering collateralized loans which have lower rates</em>
          </li>
        </ul>
      </li>
    </ul>
    <p>&nbsp;</p>
    <ul>
      <li>
        <strong>If I am in an official blacklist, am I eligible to apply for your loan?</strong>
        <ul>
          <li>
            <em>No. if you are the credit bureau as a bad credit we cannot lend to you</em>
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Can I borrow if I have no work permit</strong>
        <ul>
          <li>
            <em>No. you need to have a work permit or pink card to borrow</em>
          </li>
          <li>
            <em
              >If you are in employment but do not have a pink card please
              <span class="text-primary cursor-pointer" @click="scroll('footer')">[click here]</span></em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Can I update the information in my application form after I submitted the application</strong>
        <ul>
          <li>
            <em
              >The submitted applicant form &nbsp;will be locked for editing after submission, please inform our loan
              officer during the interview if you need to change anything.
            </em>
          </li>
          <li>
            <em
              >After the loan has been approved you can edit your information at any time by going to the &ldquo;My
              profile&rdquo; section in the app.
            </em>
          </li>
          <li>
            <em
              >You can also contact our staff via
              <span class="text-primary cursor-pointer" @click="scroll('footer')">[Link for contact channel]</span>
              if you have any further questions</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Do I need to visit Syn Pitarn&rsquo;s branch</strong>
        <ul>
          <li>
            <em
              >We need to verify that you are who you say you are. So for the first loan, once it has been approved, we
              ask that you come into our branch to complete the verification process.</em
            >
          </li>
          <li>
            F<em
              >or subsequent loans we will communicate with you through your mobile phone and conduct all the checks
              electronically</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Can I save my application form and complete it later</strong>
        <ul>
          <li>
            <em
              >Yes. By clicking next at the end of each page of the application form you will save the data that you
              have entered. You can login any time to complete the process.</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Do I need a social media account to have a loan</strong>
        <ul>
          <li>
            <em>Yes. We use Facebook Messenger, Viber or Line to contact you </em>
          </li>
          <li>
            <em>If you need to set up a social media account please click the platform you prefer below</em>
          </li>
        </ul>
      </li>
    </ul>
    <p class="text-primary cursor-pointer" @click="goToFackbook">
      <em>Facebook Messenger</em>
    </p>
    <p class="text-primary cursor-pointer" @click="goToViber"><em>Viber</em></p>
    <p class="text-primary cursor-pointer" @click="goToLine"><em>Line</em></p>
    <p><strong>&nbsp;</strong></p>
    <ul>
      <li>
        <strong>Do I need a password</strong>
        <ul>
          <li>
            <em>Yes. When you access Syn Pitarn for the first time you will be asked to set up a private account.</em>
          </li>
          <li><em>Your mobile phone number is your login name</em></li>
          <li>
            <em>You will need to setup a 6 digit PIN code. You must remember this or write it down securely</em>
          </li>
          <li>
            <em>You will need this PIN code to access your private data on our app</em>
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <p><strong>&nbsp;</strong></p>
    <ul>
      <li>
        <strong>Where do I check the loan application status?</strong>
        <ul>
          <li>
            <em
              >You can log in to Syn Pitarn app using your phone number and PIN code to check the latest status any
              time.</em
            >
          </li>
          <li>
            <em
              >We will also send you notifications via the your chosen social media channel when we need to update you;
              Application reviewed and interview date confirmed, Loan approved, Branch visit date confirmed (First loan
              only), etc.
            </em>
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Is there someone I can speak to</strong>
        <ul>
          <li>
            <em>You can contact us through Facebook Messenger chat button on the app</em>
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Is there any charge during loan application process until I receive the money</strong>
      </li>
    </ul>
    <p>
      <em
        >No charges, we will transfer the full amount of the approved loan. &nbsp;However you maybe charged a &nbsp;bank
        fee by your bank. &nbsp;</em
      >
    </p>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>What is the reason that my loan has not been approved?</strong>
        <ul>
          <li>
            <em
              >You are working or living in a province where we do not currently operate or have worked for the same
              company in Thailand for less than a year or your age is less than 20</em
            >
          </li>
          <li><em>Your documents are not complete</em></li>
          <li>
            <em>You have a bad credit history of repaying your previous loan&nbsp; </em>
          </li>
          <li>
            <em>your work permit expiry date is less than 3 months away</em>
          </li>
        </ul>
      </li>
    </ul>
    <p>
      <em
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You can still
        re-apply for a loan in 6 months if your situation has changed</em
      >
    </p>
    <p>
      <em
        >Please contact
        <span class="text-primary cursor-pointer" @click="scroll('footer')">[Link for contact channel]</span>
        if you have any further questions</em
      >
    </p>
    <p><strong>Loan repayments</strong></p>
    <ul>
      <li><strong>How do I make repayments</strong></li>
    </ul>
    <p><em>You can make payments at;</em></p>
    <p>&uuml;&nbsp; <em>Syn Pitarn office</em></p>
    <p>&uuml;&nbsp; <em>Any ATM machine [instruction link]</em></p>
    <p>&uuml;&nbsp; <em>Through your mobile bank app [instruction link]</em></p>
    <p>
      &uuml;&nbsp;
      <em>Direct debit from your bank account [instruction link]</em>
    </p>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Can someone else pay on your behalf</strong>
        <ul>
          <li>
            <em
              >Yes. But the person must scan the bar code on your invoice if paying by ATM or must come into our
              branch</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><strong>&nbsp;</strong></p>
    <ul>
      <li><strong>What are the charges</strong></li>
    </ul>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th colspan="2" scope="col">Repayment related fee*</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Via electronics channels; ATM or Mobile Banking</td>
          <td>5 Baht/transaction</td>
        </tr>
        <tr>
          <td>Via bank counter</td>
          <td>15-30 Baht/transaction</td>
        </tr>
        <tr>
          <td>Via direct debit</td>
          <td>10-30 Baht/transaction</td>
        </tr>
        <tr>
          <td>Repay at Syn Pitarn branch</td>
          <td>Free of charge</td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
    <p>
      <em>Remark</em><em>: -* Repayment related fee</em
      ><em>
        will be determined by bank or service provide, such fees are subject to change as actually charged by banks or
        service provides.</em
      >
    </p>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Where do I check the loan and repayment status?</strong>
        <ul>
          <li>
            <em
              >You can log in to Syn Pitarn&rsquo;s app &nbsp;using your phone number and PIN code to check the loan
              status and repayment schedule. &nbsp;</em
            >
          </li>
          <li>
            <em
              >We will also send you notifications via your chosen social channel&nbsp; when we update you on; Repayment
              schedule reminder, Repayment received, Late payment followed up, etc.</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Receipts</strong>
        <ul>
          <li>
            <em
              >When we have received your payment we will upload a receipt to your private account page in Syn
              Pitarn&rsquo;s app. You can log into your account to check at any time.</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>What happens if I repay less than the scheduled amount</strong>
        <ul>
          <li>
            <em
              >Interest is calculated based on outstanding amount, this mean if you repay less than the scheduled
              amount, you will need to pay more interest</em
            >
          </li>
          <li>
            <em>Moreover, the difference &nbsp;will be added to your next scheduled amount.</em>
          </li>
          <li>
            <em
              >Paying less than the scheduled amount will affect your credit history and reduce the possibility of
              getting new loans in the future</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <ul>
      <li>
        <strong>Can I repay earlier than the scheduled amount</strong>
        <ul>
          <li>
            <em
              >Yes. If you repay earlier than the schedule you will not have to pay interest on the principal that was
              repaid early</em
            >
          </li>
        </ul>
      </li>
    </ul>
    <p>&nbsp;</p>
    <ul>
      <li>
        <strong>What happens if I cannot repay a loan</strong>
        <ul>
          <li>
            <em
              >If you have a problem making a repayment you must contact us immediately
              <span class="text-primary cursor-pointer" @click="scroll('footer')">[click here]</span>
              and our loan officer will help you by restructuring your payment terms</em
            >
          </li>
          <li>
            <em>By telling us early of your problems we can help you and your credit history may not be affected</em>
          </li>
        </ul>
      </li>
    </ul>
    <p><strong>Security</strong></p>
    <ul>
      <li>
        <strong>Is my data safe</strong>
        <ul>
          <li>
            <em>Yes. We comply with the government&rsquo;s regulation &nbsp;on data privacy</em>
          </li>
        </ul>
      </li>
    </ul>
    <p><em>&nbsp;</em></p>
    <p><strong>Other</strong></p>
    <ul>
      <li>
        <strong>What happens if I change my mobile number</strong>
        <ul>
          <li>
            <em>If you change your number you will need to login to your account and make notify us of the change</em>
          </li>
        </ul>
      </li>
    </ul>
    <p><strong>&nbsp;</strong></p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  created() {
    const refName = this.$route.params.refName
    if (refName) {
      this.$nextTick(() => {
        this.scroll(refName)
      })
    }
  },
  methods: {
    goToBranch() {
      this.$router.push({
        name: 'landing',
        params: {
          refName: 'branch',
        },
      })
    },
    goToMessenger() {
      window.open('http://m.me/105351764988706', '_blank')
    },
    goToFackbook() {
      window.open('https://www.facebook.com/', '_blank')
    },
    goToViber() {
      window.open('https://www.viber.com/', '_blank')
    },
    goToLine() {
      window.open('https://line.me/', '_blank')
    },
    goToRegister() {
      this.$router.push({
        name: 'register',
      })
    },
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style></style>
