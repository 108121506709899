<template>
  <div>
    <div class="container" fluid="lg">
      <h4 class="font-weight-bold text-center my-4">{{ $t('faq') }}</h4>
      <faq-english v-if="$i18n.locale == 'en'"></faq-english>
      <faq-thai v-else-if="$i18n.locale == 'th'"></faq-thai>
      <faq-myanmar v-else></faq-myanmar>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import FaqMyanmar from './faq/FaqMyanmar.vue'
import FaqEnglish from './faq/FaqEnglish.vue'
import FaqThai from './faq/FaqThai.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: { FaqMyanmar, FaqEnglish, FaqThai, Footer },
}
</script>

<style></style>
