<template>
  <div class="text-justify">
    <p>
      <strong>
        <span style="text-decoration: underline">Syn Pitarn</span>
      </strong>
    </p>
    <p><strong>#Syn Pitarn ဆိုတာ ဘာအဖွဲ့အစည်းလဲ။</strong></p>
    <p>
      -Syn Pitarn သည် ထိုင်းနိုင်ငံရှိ ဝင်‌ငွေနည်းသော ရွှေ့ပြောင်း အလုပ်သမားများအတွက် ဘဏ္ဍာရေး ကိစ္စများကို
      ပါဝင်လုပ်ဆောင်ပေးနေသည့် ထိုင်းငွေရေးကြေးရေးဝန်ဆောင်မှု အဖွဲ့အစည်းတစ်ခု ဖြစ်ပါသည်။
    </p>
    <p>-ကျွန်ုပ်တို့ ၏အဓိက ဝန်ဆောင်မှုမှာ ချေးငွေများ ထုတ်ချေးပေးခြင်းဖြစ်သည်။</p>
    <p>
      -ကျွန်ုပ်တို့၏ဝန်ဆောင်မှုများကို ပထုမ်ထားနီးမြို့တွင် စတင်ချေးမည်ဖြစ်ပြီး နောင်လာမည့်လများတွင်လည်း
      အခြားမြို့များတွင် တိုးချဲ့လုပ်ဆောင်သွားမည်ဖြစ်ပါသည်။
    </p>
    <br />
    <p>
      <strong>#လိုင်စင်ရှိမရှိ/ တရားဝင်အဖွဲ့အစည်းဟုတ်မဟုတ်။</strong>
    </p>
    <p>
      -Syn Pitarn သည် “ပီကို ဘဏ္ဍာရေးဝန်ဆောင်မှုကုမ္ပဏီ” အဖြစ် ဘဏ္ဍာရေးဝန်ကြီးဌာနမှ လိုင်စင်ရထားပြီး ဘဏ္ဍာရေး
      လုပ်ငန်းများ တရားဝင် လုပ်ဆောင်နေပါသည်။
    </p>
    <br />
    <p>
      <strong>#Syn Pitarn မှ ချေးငွေရယူခြင်း၏ အကျိုးကျေးဇူးရလဒ် များ</strong>
    </p>
    <p>-တရားဝင်ဖြစ်ခြင်း</p>
    <p>-ပွင့်လင်းမြင်သာမှုရှိခြင်း</p>
    <p>-မြန်မာဘာသာဖြင့် မြင်တွေ့နိုင်ခြင်း (သို့) အသုံးပြုနိုင်ခြင်း</p>
    <p>
      -ကျွန်ုပ်တို့၏ အတိုးနှုန်းကို ထိုင်းအစိုးရမှ တရားဝင်သတ်မှတ်ထား သည့်အတိုင်း တစ်လလျှင် ၃ ရာခိုင်နှုန်းသတ်မှတ်ထားသည်။
    </p>
    <p>
      -သင့်ထံတွင် ကောင်းမွန်သော ချေးငွေ မှတ်တမ်းရှိပါက ကျွန်ုပ်တို့အနေဖြင့် အထက်ပါ အတိုးနှုန်းထားကို လျှော့ပေးနိုင်ရန်
      စဉ်းစားပေးပါမည်။
    </p>
    <p class="text-primary cursor-pointer" @click="scroll('lower-rate')">
      (သက်ဆိုင်ရာ မေးလေ့ရှိသော မေးခွန်းကို နှိပ်ပါ)
    </p>
    <p>-လျှို့ဝှက်ပေးဆောင်ရသော (သို့)အခြား အခကြေးငွေများ ထပ်မရှိပါ။</p>
    <p>
      - နောက်ပိုင်းအဆင်ပြေချောမွေ့စေရန်အတွက် ကျွန်ုပ်တို့ သက်ဆိုင်ရာဌာနခွဲသို့ တစ်ကြိမ်သာ လာရောက်ရမည်ဖြစ်ပြီး
      ဝန်ဆောင်မှု အများစုကို အချိန်မရွေး အွန်လိုင်း/အင်တာနက်တွင် ဝင်ရောက်ကြည့်ရှုနိုင်ပါသည်။
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ချေးငွေမရမှီ ဘာများပေးဆောင်ရန်လိုအပ်ပါသနည်း။</strong>
    </p>
    <p>-မလိုပါ၊ ချေးငွေမရမှီဘာမျှပေးဆောင်ရန်မလိုအပ်ပါ။</p>
    <p>-လျှို့ဝှက်ပေးဆောင်ရသော အခကြေးငွေများမရှိပါ။</p>
    <p>
      -ကျွန်ုပ်တို့သည် မည်သည့်ကြားခံလူ / ပွဲစားများကိုမသုံးထားသောကြောင့် Syn Pitarn မှ ချေးငွေရယူနိုင်ရန်
      ကူညီပေးမည်ဟုဆိုသော မည်သည့်လူကိုမဆို အခကြေးငွေပေးရန် မလိုအပ်ပါ
    </p>
    <br />
    <p>
      <strong>#Syn Pitarn သည် သင့်ဘဝပိုမို လွယ်ကူစေရန် ရည်ရွယ်သည်။</strong>
    </p>
    <p>-ဂုဏ်ယူပါသည်။</p>
    <p>
      -Syn Pitarn ၏ ဝန်ဆောင်မှုများကို ရယူခြင်းဖြင့် ဘဏ္ဍာရေး နည်းပညာနယ်ပယ်မှ အကျိုးကျေးဇူးများကို သင်/သင်တို့
      ရရှိမည်ဖြစ်သည်။
    </p>
    <p>-သင်၏လက်ကိုင်ဖုန်းကို အသုံးပြု၍ အဆင်ပြေလွယ်ကူသည့် အချိန်တိုင်း ကျွန်ုပ်တို့နှင့် ဆက်သွယ်နိုင်ပါသည်။</p>
    <p>
      -သင်၏ချေးငွေ ရလဒ် အခြေအနေနှင့် ပြန်လည်ပေးဆပ်ရမည့် အချိန်ဇယားများကို သင်၏ လက်ကိုင်ဖုန်းနှင့်
      ဝင်ရောက်ကြည့်ရှုနိုင်ပါသည် (သို့) ချေးငွေအသစ်တစ်ခု ထပ်မံလျှောက်ခြင်း များလုပ်နိုင်ပါသည်။
    </p>
    <br />
    <p><strong>#ရုံးဖွင့်ချိန်</strong></p>
    <p>-ကျွန်ုပ်တို့သည် နေ့စဉ် ညနေပိုင်းများ နှင့် စနေ၊ တနင်္ဂနွေ များတွင်လည်းရုံးဖွင့်ထားပါသည်။</p>
    <br />
    <p>
      <strong>#Syn Pitarn ကိုဘယ်လိုနည်းလမ်းတွေနဲ့ ဆက်သွယ်လို့ရနိုင်ပါသလဲ။</strong>
    </p>
    <p>
      -ကျေးဇူးပြု၍ မက်ဆင်ဂျာ (Messenger) ကနေတစ်ဆင့် ဆက်သွယ်ပေးပါ
      <span class="text-primary cursor-pointer" @click="goToMessenger">(ဤနေရာတွင်နှိပ်ပါ)</span>
      (သို့မဟုတ်)
    </p>
    <p>
      -ကျွန်ုပ်တို့၏ ရုံးခွဲကို လိပ်စာပါအတိုင်းလာရောက်စုံစမ်းမေးမြန်းနိုင်ပါသည်။
      <span class="text-primary cursor-pointer" @click="goToBranch"> (ရုံးလိပ်စာသို့ ဤနေရာတွင်နှိပ်ပါ) </span>
    </p>
    <br />
    <p>
      <strong>
        <span style="text-decoration: underline">ချေးငွေများ</span>
      </strong>
    </p>
    <br />
    <p>
      <strong>#</strong> <strong>ချေးငွေအတွက်</strong>
      <strong>သင့်ထံ ရှိသင့်သည့်အချက်အလက်များ</strong>
    </p>
    <p>-ပထုမ်ထားနီးတွင် နေထိုင်သူ (သို့) အလုပ်လုပ်နေသူဖြစ်ရမည်။</p>
    <p>-အနည်းဆုံးအသက် (၂ဝ) နှစ်နှင့်အထက် ရှိသူဖြစ်ရမည်။</p>
    <p>
      -ထိုင်းနိုင်ငံတွင် အနည်းဆုံး လုပ်သက် (၂) နှစ် နှင့် ထိုင်းနိုင်ငံတွင်း ကုမ္ပဏီတစ်ခုတည်းတွင် လုပ်သက် (၁) နှစ်
      လုပ်ကိုင်နေသူ ဖြစ်ရမည်။
    </p>
    <p>
      -ချေးငွေ၏နောက်ဆုံး အကျေပြန်လည်ပေးဆပ်သည့်ရက်စွဲမှ စ၍ အနည်းဆုံး (၃) လသက်တမ်းကျန်ရှိသော အလုပ်လုပ်ကိုင်ခွင့် ပါမစ်
      (ပန်းရောင်ကတ်) ၊ MOU လက်ဝယ်ရှိရမည်။
    </p>
    <p>-ထိုင်းဘဏ်အကောင့်တစ်ခုရှိရမည်။</p>
    <p>-ဖေ့ဘုတ် (Facebook) / ဗိုက်ဘာ (Viber) သို့ လိုင်း (Line) တစ်ခုခုရှိရမည်။</p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ချေးငွေလျှောက်ရန် လုပ်ငန်းဆောင်ရွက်ချိန် ဘယ်လောက်ကြာပါသနည်း။</strong>
    </p>
    <p>
      -ချေးငွေလျှောက်လွှာကို သင်၏လက်ကိုင်ဖုန်းမှ တင်သွင်းလျှောက်ထားစဉ်တွင် ကျွန်ုပ်တို့ ကုမ္ပဏီ၏
      မြန်မာချေးငွေဆိုင်ရာအရာရှိနှင့် Video ဖုန်း ခေါ်ဆိုမှုပြုလုပ်ရန်အတွက် အချိန်ကို ရွေးချယ်ခိုင်းပါမည်။
    </p>
    <p>ဒီအဆင့်မှာပုံမှန်အားဖြင့် လျှောက်လွှာတင်ပြီး ၃ ရက်မှ ၅ ရက်အတွင်းကြာမြင့်မည်ဖြစ်သည်။</p>
    <p>
      -ကျွန်ုပ်တို့၏မြန်မာချေးငွေဆိုင်ရာအရာရှိနှင့် Videoဖုန်း ပြောဆိုပြီး ချေးငွေအတည်ပြုချက်ရရှိပါက
      သင်ကိုယ်တိုင်အတည်ပြုရန်အတွက် ကျွန်ုပ်တို့ ရုံးခွဲသို့ သွားရောက်ရန် လိုအပ်ပါသည်။
      တစ်ကြိမ်သာသွားရောက်ဖို့လိုအပ်ပါသည်။
    </p>
    <p>-အတည်ပြုထားသော ချေးငွေ ပမာဏကို ကျွန်ုပ်တို့မှ သင်၏ ဘဏ် အကောင့်အတွင်းသို့ နေ့ချင်း လွှဲပေးမည် ဖြစ်ပါသည်။</p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ကျွန်ပ်အနေဖြင့် သင်တို့၏ရုံးခွဲသို့ သွားရန်လိုအပ်ပါသလား။</strong>
    </p>
    <p>
      -လိုအပ်ပါသည်။ အဘယ်ကြောင့်ဆိုသော် ကျွန်ုပ်တို့အနေနှင့် သင်လျှောက်ခဲ့သော စာရွက်စာတမ်းများနှင့် ကိုက်ညီသူဖြစ်ကြောင်း
      စစ်ဆေးရန်လိုအပ်သောကြောင့် ဖြစ်ပါသည်။
    </p>
    <p>-ထိုသို့ စစ်ဆေးရန်အတွက် သင့်အနေနှင့်ရုံးခွဲသို့ တစ်ကြိမ်သာ သွားရောက်ရမည်ဖြစ်သည်။</p>
    <p>ထိုအဆင့်ပြီးသည့်နောက် သင်၏လက်ကိုင်ဖုန်းနှင့် ကျန်သောအချက်များအားလုံး ဆက်လက်လုပ်ကိုင်သွား လို့ရပါပြီ။</p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ကျွန်ပ် အနေနှင့် ပမာဏ ဘယ်လောက်ထိချေးနိုင်မည်နည်း။</strong>
    </p>
    <p>-ပထမဆုံးအကြိမ် ချေးငွေအနေဖြင့် အများဆုံး ဘတ် (၅,၀၀၀) ချေးယူနိုင်ပါသည်။</p>
    <p>
      -သင်၏ ချေးငွေပြန်ဆပ်မှုမှတ်တမ်း ပေါ်မူတည်၍ ချေးငွေပမာဏတိုးခြင်း နှင့် ချေးငွေကာလတိုးခြင်းတို့အား
      လျှောက်ထားလုပ်ဆောင်နိုင်ပါသည်။
    </p>
    <p>-Syn Pitarn မှချေးပေးနိုင်သော အများဆုံး ချေးငွေပမာဏ မှာ ဘတ် (၅၀,၀၀၀) ဖြစ်ပါသည်။</p>
    <p>
      <strong>#Syn Pitarn မှချေးငွေ အတိုးနှုန်းသည် မည်မျှရှိသနည်း။</strong>
    </p>
    <p>-ကျွန်ုပ်တို့၏အတိုးနှုန်းမှာ အစိုးရမှ တရားဝင်သတ်မှတ်ထားသည့်အတိုင်း တစ်လလျှင် (၃ ရာခိုင်နှုန်း) ဖြစ်ပါသည်။</p>
    <p>
      -ကျွန်ုပ်တို့၏အတိုးနှုန်းမှာ အရင်းလျော့အတိုးလျော့စနစ်ဖြစ်ပြီး ဆိုလိုရင်းမှာ အတိုးကိုကျန်ရှိနေသည့်
      ချေးငွေအပေါ်တွင်သာ တွက်ချက်ခြင်းဖြစ်သည်။
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ချေးငွေမှတ်တမ်းဆိုသည်မှာ ဘာကိုဆိုလိုပါသနည်း။</strong>
    </p>
    <p>
      -ချေးငွေ မှတ်တမ်းဆိုသည်မှာ သင်ချေးယူထားသော ချေးငွေများကို မည်သို့ မည်ပုံ မည်၍ မည်မျှ ပြန်လည်ပေးဆပ်ခဲ့သည်ကို
      မှတ်တမ်းတင်ထားသော မှတ်တမ်းကို ဆိုလိုပါသည်။
    </p>
    <p>
      -သဘောတူသတ်မှတ်ထားသောရက်စွဲတွင် သတ်မှတ်ပမာဏကို သေချာစွာပြန်လည်ပေးဆပ်ခြင်းဖြင့် သင်သည်လွယ်ကူချောမွေ့သော
      ချေးငွေမှတ်တမ်းကောင်းကို ရရှိပါမည်။
    </p>
    <p id="lower-rate">
      <strong>#</strong>
      <strong>ကျွန်ုပ်အနေနှင့် အတိုးနှုန်း လျော့၍ရရှိနိုင်ပါသလား။</strong>
    </p>
    <p>
      -ရရှိနိုင်ပါသည်။ အကယ်၍ သင့်ဆီမှာသာ ကောင်းမွန်သော ချေးငွေမှတ်တမ်း တစ်ခုရှိနေခဲ့ပါက အတိုးနှုန်းကို
      လျှော့ချပေးနိုင်ရန် ကျွန်ုပ်တို့အနေနှင့်စဉ်းစားပေးပါမည်။
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ချေးငွေသက်တမ်းအချိန်ဘယ်လောက်ကြာကြာ ရနိုင်မည်နည်း။</strong>
    </p>
    <p>
      -သင်၏ပထမဆုံးချေးငွေအတွက် (၂) လမှ (၃) လအတွင်း ဖြစ်ပြီး ၊ (၂) ပတ်လျှင် တစ်ကြိမ်နှုန်းဖြင့် (၄) ကြိမ် အရစ်ကျစနစ်ဖြင့်
      ပြန်လည်ပေးဆပ်ရမည်ဖြစ်သည်။
    </p>
    <p>
      -သင်၏ပထမဆုံးချေးငွေထုတ်ပြီးနောက်ပိုင်း သင်၏ ချေးငွေမှတ်တမ်းကောင်းတစ်ခုရှိပါက ပမာဏပိုများသော ချေးငွေ
      ထပ်မံရရှိနိုင်ရန် နှင့် (၆) လအထိသက်တမ်းတိုးပေးရန် ဆွေးနွေးနိုင်ပါသည်။
    </p>
    <p>
      <strong>#</strong>
      <strong>ကျွန်ုပ်အနေနှင့် ချေးငွေကို ဘယ်လို ပြန်လည်ပေးဆပ်ရမည်နည်း ။</strong>
    </p>
    <p>
      -ပုံမှန်ဆိုလျှင် (၂) ပတ် တစ်ကြိမ်ပေးရသော်လည်း မှတ်တမ်းကောင်းရှိသူများအတွက် (၁) လ တစ်ကြိမ် ပြန်လည်ပေးဆပ်ခွင့်ရရန်
      စဉ်းစားပေးပါသည်။
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ပြန်လည်ပေး ဆပ်မှုနောက်ကျပါက ဒဏ်ကြေးရှိပါသလား။</strong>
    </p>
    <p>
      -အကယ်၍ သင်သည် ပြန်လည်ပေးဆပ်မှုကို (၅) ရက် ထက်နောက်ကျပါက ပိုမိုလုပ်ဆောင်ရသော ဝန်ဆောင်မှုများအတွက် တစ်လကို ဘတ် (၅ဝ)
      ထပ်မံကောက်ခံပါမည်။
    </p>
    <p>
      -ပြန်လည်ပေးဆပ်မှုနောက်ကျခြင်းသည် သင့်ချေးငွေမှတ်တမ်းနှင့် နောင်အနာဂတ်တွင် ချေးငွေထပ်မံရယူမှု တို့ကိုလည်း
      ထိခိုက်စေ/ အကျိုးသက်ရောက်မှုရှိနိုင်ပါသည်။
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ကျွန်ုပ်အနေဖြင့် ချေးငွေတစ်ခုအထက်ပိုရယူနိုင်ပါသလား။</strong>
    </p>
    <p>
      -မရနိုင်ပါ။ သင့်အနေနှင့် တစ်ကြိမ်လျှင်ချေးငွေတစ်ခုသာရနိုင်ပါသည်။ သင့်လက်ရှိချေးငွေကို အကျေပြန်ဆပ်ပြီးပါက
      ချေးငွေအသစ်တစ်ခုပြန်လည်လျှောက်ထားနိုင်ပါသည်။
    </p>
    <p>
      -သင်၏ နောက်ဆုံးချေးငွေမှာ (၆) လမကျော်လွန်သေးပါက နောက်ထပ်ချေးငွေအသစ်အတွက် ကျွန်ုပ်တို့၏ရုံးခွဲသို့
      ထပ်မံလာရောက်ရန်မလိုအပ်ပါ။
    </p>
    <br />
    <p><strong>#</strong> <strong>ချေးငွေကို ဘယ်မှာလာယူရမှာပါလဲ။</strong></p>
    <p>-ကျွန်ုပ်တို့အနေနှင့် သင်၏ချေးငွေမှန်သမျှကို သင့်ဘဏ်အကောင့်ထဲသို့သာအမြဲတမ်းတိုက်ရိုက်လွှဲပေးပါမည်။</p>
    <br />
    <p>
      <strong>#</strong>
      <strong
        >ချေးငွေကို ကျွန်ုပ်၏နိုင်ငံသားကဒ်နှင့်အလုပ်သမားပါမစ်ကဒ်ထဲရှိအမည်နှင့် မတူသောအခြားနာမည် ဘဏ်အကောင့်သို့
        လွှဲပေးလို့ရပါသလား ။</strong
      >
    </p>
    <p>-မရပါ။ သင့်အထောက်အထားစာရွက်များပါသောနာမည်နှင့် ဘဏ်အကောင့်သို့သာလွှဲပေးမည်ဖြစ်သည်။</p>
    <p>&nbsp;</p>
    <p>
      <strong>
        <span style="text-decoration: underline">ချေးငွေလျှောက်ထားခြင်းအဆင့်များ</span>
      </strong>
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ကျွန်ုပ်အနေနဲ့ချေးငွေကိုဘယ်လိုလျှောက်ရမလဲ။</strong>
    </p>
    <p>
      -ကျေးဇူးပြု၍ချေးငွေလျှောက်လွှာကိုဤနေရာတွင်နှိပ်၍ ဖြည့်ပြီးစတင်လိုက်ပါ။
      <span class="text-primary cursor-pointer" @click="goToRegister">(ဤနေရာတွင်နှိပ်ပါ)</span>
    </p>
    <br />
    <p><strong>#</strong> <strong>ဘယ်စာရွက်စာတမ်းတွေ တင်ပြဖို့လိုပါသလဲ ။</strong></p>
    <p>-သင့်ရဲ့လက်ကိုင်ဖုန်းမှတစ်ဆင့် စာရွက်စာတမ်း (၄) ခု သာတင်ပြရန် လိုအပ်ပါသည်။</p>
    <p>
      -သင့်မျက်နှာကို ဓါတ်ပုံရိုက်ပါ။ ပုံရိုက်သောအခါ ကြည်လင်သောရုပ်ပုံထွက်ရမည်။ ဉီးထုပ်မပါ၊နေကာမျက်မှန်မပါစေဘဲ အဖြူရောင်
      နောက်ခံထား၍ရိုက်ပါ။
    </p>
    <p>-သင်၏ထိုင်းအလုပ်သမားကဒ် (အလုပ်ပါမစ်) သို့မဟုတ် ပန်းရောင်ကဒ် (အရှေ့/အနောက်)</p>
    <p>
      -သင်၏မြန်မာ မှတ်ပုံတင်ကဒ် (အရှေ့/အနောက်) (သို့) နိုင်ငံကူးလက်မှတ် (သင့်ဓာတ်ပုံပါသော ရှေ့ဆုံးစာမျက်နှာ နှင့်
      ဗီဇာသက်တမ်းပါသောစာမျက်နှာ)
    </p>
    <p>-သင်၏နောက်ဆုံးလစာလက်ခံစာရွက် (သို့) နောက်ဆုံးလစာရရှိမှုကိုဖော်ပြသော ဘဏ်စာရင်းရှင်းတမ်း (သို့) ဘဏ်စာအုပ်</p>
    <p>-ကျွန်ုပ်တို့ အကြံပေးလိုသည်မှာ ချေးငွေလျှောက်လွှာ မဖြည့်ခင် အထက်ပါစာရွက်စာတမ်းများကို အရင်ပြင်ဆင်စေလိုပါသည်။</p>
    <br />
    <p>
      <strong>#ကျွန်ုပ်အနေနှင့် Syn Pitarn မှ ချေးငွေရယူရန် အတွက် အပေါင် ထားရှိရန် လိုအပ်ပါသလား ။</strong>
    </p>
    <p>-ကျွန်ုပ်တို့ဆီမှ ချေးငွေရယူရန် အတွက် မည်သည့် အပေါင်မျှ ထားရှိရန်မလိုအပ်ပါ။</p>
    <p>
      <strong>#</strong>
      <strong>အကယ်၍ ကျွန်ုပ်တွင် အပေါင်ရှိပါကချေးငွေရယူရန်ပိုမိုလွယ်ကူပါသလား။ (</strong>
      <strong>သို့) ချေးငွေပိုမိုရရှိနိုင်သလား။</strong>
    </p>
    <p>-နောင်အနာဂတ်မှာတော့ အပေါင်လက်ခံ၍ အတိုးနှုန်းသက်သာသောချေးငွေများထုတ်ချေးရန်ရှိပါသည်။</p>
    <br />
    <p>
      <strong>#</strong>
      <strong>အကယ်၍ ကျွန်ုပ်သည် အမည်ပျက်စာရင်းတွင် တရားဝင်ပါရှိနေပါက ချေးငွေရယူရန် အဆင်ပြေနိုင်ပါသလား။</strong>
    </p>
    <p>-အဆင်မပြေပါ။ အကယ်၍ သင့်မှာချေးငွေမှတ်တမ်း မကောင်းကြောင်းတွေ့ရှိပါက သင့်အား ချေးငွေမချေးပေးပါ။</p>
    <p>
      <strong>#</strong>
      <strong>အကယ်၍ ကျွန်ုပ်မှာ အလုပ်သမားကဒ် (အလုပ်ပါမစ်) မရှိဘဲ ငွေချေးလို့ရနိုင်ပါသလား။</strong>
    </p>
    <p>-မရပါ။ သင့်ထံမှာထိုင်းအလုပ်သမားကဒ် (အလုပ်ပါမစ်) သို့မဟုတ် ပန်းရောင်ကဒ်ရှိမှသာ ချေးငွေ ချေးယူနိုင်ပါသည်။</p>
    <p>
      -အကယ်၍ သင့်မှာအလုပ်ရှိသော်လည်း ပန်းရောင်ကဒ်မရှိပါက
      <span class="text-primary cursor-pointer" @click="scroll('footer')">ဤနေရာတွင်နှိပ်ပါ။</span>
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>လျှောက်လွှာတင်ပြီးနောက် ကျွန်ုပ်၏အချက်အလက်များကို လျှောက်လွှာဖောင်တွင် ပြန်လည်ပြင်ဆင်လို့ရပါသလား။</strong>
    </p>
    <p>
      -တင်သွင်းပြီးသောလျှောက်လွှာသည် တင်သွင်းပြီးနောက် ပြင်ဆင်ရန်အတွက်လောခ့်ကျသွားလိမ့်မည်ဖြစ်သည်။
      သင့်အနေနှင့်တစ်စုံတစ်ရာပြောင်းလဲလိုပါက အင်တာဗျူးချိန်တွင် ကျွန်ုပ်တို့၏ချေးငွေအရာရှိထံသို့
      အကြောင်းကြားရမည်ဖြစ်သည်။
    </p>
    <p>
      -ချေးငွေအတည်ပြုပြီးနောက် အက်ပလီကေးရှင်းတွင် &ldquo;ကျွန်ုပ်၏ကိုယ်ရေးအချက်အလက်များ&rdquo; သို့သွား၍
      သင့်အချက်အလက်များကို မည်သည့်အချိန်မဆို ပြင်ဆင်နိုင်ပါသည်။
    </p>
    <p>
      -အကယ်၍ သင်သိလိုသည်များကို မေးမြန်းလိုပါက ဆက်သွယ်ရေးလမ်းကြောင်းလင့်ခ်မှတစ်ဆင့် ကျွန်ုပ်တို့၏ ဝန်ထမ်းများကို
      ဆက်သွယ်မေးမြန်းနိုင်ပါသည်။
      <span class="text-primary cursor-pointer" @click="scroll('footer')">(ဆက်သွယ်ရန်ဤနေရာတွင်နှိပ်ပါ။)</span>
    </p>
    <br />
    <p>
      <strong>#ကျွန်ုပ်အနေနှင့် Syn Pitarn ရုံးခွဲသို့ သွားရောက်ရန် လိုအပ်ပါသလား။</strong>
    </p>
    <p>
      -သင်နှင့်သင့်အချက်လက်များကို ကျွန်ုပ်တို့ အတည်ပြုရန်လိုအပ်ပါသည်။ ထို့ကြောင့် ပထမဆုံးချေးငွေ အတည်ပြုပြီးသည့်နောက်
      ကျွန်ုပ်တို့၏ရုံးခွဲသို့လာရောက်၍ ချေးငွေဆိုင်ရာအထောက်အထားများ လာပြရမည် ဖြစ်သည်။
    </p>
    <p>
      -နောက်ပိုင်းချေးငွေများအတွက်ကိုမူ ကျွန်ုပ်တို့က သင့်လက်ကိုင်ဖုန်းမှတစ်ဆင့်ဆက်သွယ်မည်ဖြစ်ပြီး
      အီလက်ထရောနစ်စနစ်ဖြင့်သာ လုပ်ဆောင်သွားမည်ဖြစ်သည်။
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ကျွန်ုပ်၏ချေးငွေလျှောက်လွှာ ကို သိမ်းဆည်းထားပြီးနောက်မှ ပြန်လည်ဖြည့်စွက်၍ ရပါသလား။</strong>
    </p>
    <p>
      -ရပါတယ်။ လျှောက်လွှာစာမျက်နှာတိုင်း၏အဆုံးသတ်တွင် ဆက်လက်၍ ကို နှိပ်ခြင်းအားဖြင့် သင်ထည့်သွင်းပြီးသော
      အချက်အလက်များကို အလိုအလျောက်သိမ်းဆည်းမည်ဖြစ်သည်။ အပြီးသတ်ဖြည့်စွက်ရန် သင် အချိန်မရွေး ဝင်ရောက်ဖြည့်စွက်နိုင်ပါသည်။
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ကျွန်ုပ်အနေနှင့် ချေးငွေရယူရန် လူမှုစာမျက်နှာကွန်ယက်တွင် အကောင့်တစ်ခုရှိဖို့လိုအပ်ပါသလား။</strong>
    </p>
    <p>
      လိုပါတယ်။သင့်ကိုဆက်သွယ်ရန် ကျွန်ုပ်တို့အနေနှင့် Facebook မက်ဆင်ဂျာ ၊ ဗိုက်ဘာ ၊ ( သို့ ) လိုင်း များကို
      အသုံးပြုမည်ဖြစ်ပါသည်။
    </p>
    <p>-အကယ်၍ သင်အကောင့်တစ်ခုပြုလုပ်လိုပါက ကျေးဇူးပြု၍ အောက်ပါတို့ကို နှိပ်၍ ပြုလုပ်နိုင်ပါသည်။</p>
    <p class="text-primary cursor-pointer" @click="goToFackbook">Facebook မက်ဆင်ဂျာ (Facebook messenger)</p>
    <p class="text-primary cursor-pointer" @click="goToViber">ဗိုက်ဘာ (Viber)</p>
    <p class="text-primary cursor-pointer" @click="goToLine">လိုင်း (Line)</p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ကျွန်ုပ်အနေနှင့် စကားဝှက်တစ်ခုပြုလုပ်ရန်လိုပါသလား ။</strong>
    </p>
    <p>-လိုပါတယ်။ Syn Pitarn ကိုပထမဆုံးအကြိမ်ဝင်ရောက်ပါက သင့်အား သီးသန့်အကောင့်တစ်ခုဖွင့်ရန် ပြောပါလိမ့်မည်။</p>
    <p>-သင့်လက်ကိုင်ဖုန်းနံပါတ်သည် သင့်အကောင့်ဝင်ရန် အမည် (Login Name) ဖြစ်သည်။</p>
    <p>
      -ဂဏန်း (6) လုံးကို ပင်ကုဒ်အဖြစ် သင်ထည့်သွင်းရမည်ဖြစ်သည်။ထို ဂဏန်း (6) လုံးကို မှတ်သားထားရမည် (သို့)
      လုံခြုံသောတစ်နေရာတွင် ချရေးထားရမည်။
    </p>
    <p>
      -ကျွန်ုပ်တို့၏ အက်ပလီကေးရှင်းတွင် သင့်ကိုယ်ရေးအချက်အလက်များကို ဝင်ရောက်ကြည့်ရှုရန် အထက်ပါပင်ကုဒ်ကို လိုအပ်ပါသည်။
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ကျွန်ုပ်၏ချေးငွေ အတည်ပြုမပြုကို ဘယ်မှာစစ်ရမလဲ ။</strong>
    </p>
    <p>
      - Syn Pitarn အက်ပလီကေးရှင်းတွင် သင့်လက်ကိုင်ဖုန်း နံပါတ်နှင့် ပင်ကုဒ်ကို အသုံးပြု၍ အချိန်မရွေး
      ဝင်ရောက်စစ်ဆေးနိုင်သည်။
    </p>
    <p>-သင်ရွေးချယ်ထားသော လူမှုစာမျက်နှာမှတစ်ဆင့် ကျွန်ုပ်တို့အသိပေးလိုသည်များ ၊အကြောင်းကြားစာများ ပို့ပေးပါမည်။</p>
    <p>
      -လျှောက်လွှာကို သုံးသပ်ပြီးနောက် (Video ဖုန်း ခေါ်ဆိုမှုဖြင့်) လူတွေ့ အင်တာဗျူး အတည်ပြုပေးမည်ဖြစ်သည်။
      ပြီးလျှင်ချေးငွေအတည်ပြုပါမည်။ ရုံးခွဲသို့လာရောက်ရမည့်ရက်ကိုအတည်ပြုပါမည်။ (ပထမဆုံး ချေးငွေအတွက်သာ)
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ကျွန်ုပ်နှင့်စကားပြောနိုင်သောသူ ရှိပါသလား။</strong>
    </p>
    <p>-အက်ပလီကေးရှင်းတွင်ရှိသော ဖေ့ဘုတ်မက်ဆင်ဂျာ (Facebook Messenger) မှတစ်ဆင့် ကျွန်ုပ်တို့ကို ဆက်သွယ်၍ရပါသည်။</p>
    <p>
      <strong>#</strong>
      <strong>ချေးငွေလျှောက်ထားချိန်မှ ငွေလက်ခံရရှိသည့်အချိန်ထိ အခကြေးငွေပေးရန်လိုပါသလား။</strong>
    </p>
    <p>-ပေးရန်မလိုပါ။ အတည်ပြုပြီးချေးငွေ ပမာဏအားလုံးကို ကျွန်ုပ်တို့က လွှဲပေးပါမည်။</p>
    <p>ဘဏ်မှ လွှဲခ ကောက်ခံပါက သင့်ဘက်မှ &nbsp;ပေးဆောင်ရန် လိုအပ်ပါသည်။</p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ကျွန်ုပ်၏ချေးငွေကို အတည်မပြုရခြင်းအကြောင်းရင်း အဘယ်နည်း။</strong>
    </p>
    <p>
      -ကျွန်ုပ်တို့လုပ်ငန်းမလည်ပတ်သေးသောနေရာတွင် သင်ကအလုပ်လုပ်နေသည် (သို့) နေထိုင်သည် (သို့) ထိုင်းနိုင်ငံရှိ
      ကုမ္ပဏီတခုတည်းတွင် လုပ်သက်တစ်နှစ်အောက်ပဲရှိသေးသည် (သို့) သင့်အသက် (၂ဝ) အောက်ပဲ ရှိသေးသည်။
    </p>
    <p>-သင့်စာရွက်စာတမ်းများမပြည့်စုံပါ။</p>
    <p>-သင်၏ ယခင်ချေးငွေတွင် မှတ်တမ်းဆိုးရှိနေပါသည်။</p>
    <p>-သင့်အလုပ်ပါမစ်သက်တမ်း ကုန်ဆုံးရက်သည် (3) လအောက်တွင်ရှိနေသည်။</p>
    <p>-သင့်အခြေအနေပြောင်းလဲမှုရှိပါက (6) လ အတွင်း ပြန်လည်လျှောက်ထားနိုင်ပါသည်။</p>
    <p>
      -နောက်ထပ်သိလိုသည်များရှိပါက
      <span class="text-primary cursor-pointer" @click="scroll('footer')">ဆက်သွယ်ရေးလိုင်းမှ</span>တစ်ဆင့် ကျေးဇူးပြု၍
      ဆက်သွယ်ပါ။
    </p>
    <br />
    <p>
      <strong>
        <span style="text-decoration: underline">ချေးငွေပြန်ဆပ်ခြင်း</span>
      </strong>
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ချေးငွေပြန်လည်ပေးဆပ်ခြင်းကို ဘယ်လိုလုပ်ရမလဲ။</strong>
    </p>
    <p>သင်၏ ချေးငွေပေးဆပ်ခြင်းများကို</p>
    <p>-Syn Pitarn ရုံး</p>
    <p>-မည်သည့်ငွေထုတ်စက်မဆို</p>
    <p>-သင့်လက်ကိုင်ဖုန်းမှ မိုဘိုင်းဘဏ် အက်ပလီကေးရှင်း</p>
    <p>-သင့်ဘဏ်အကောင့်မှတိုက်ရိုက်ငွေလွဲခြင်း</p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ကျွန်ုပ်ကိုယ်စား တစ်ယောက်ယောက်က ပြန်ဆပ်ပေးလို့ရလား။</strong>
      <strong>ဥပမာ- မိသားစုဝင်များ ( သို့ ) သူငယ်ချင်း</strong>
    </p>
    <p><strong>သင့်ကိုယ်စားတစ်ယောက်ယောက်က ပေးလို့ရနိုင်လား။</strong></p>
    <p>
      -ရပါသည်။ ကိုယ်စားသွင်းပေးမည့်သူသည် ငွေထုတ်စက်မှတစ်ဆင့် ထည့်သွင်းပေးပါက သင့်၏ငွေစာရင်းပြေစာပေါ်မှ ဘားကုဒ်ကို
      စကန်ဖတ်ပေးရမည်။ (သို့) ကျွန်ုပ်တို့၏ ရုံးခန်းသို့ လာရမည်။
    </p>
    <br />
    <p><strong>#</strong> <strong>ပေးရမည့်အခကြေးငွေတွေကဘာတွေလဲ ။</strong></p>
    <p><strong></strong></p>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th colspan="2" scope="col">ငွေပေးသွင်းမှုနှင့် ဆက်စပ်သော အခကြေးငွေများ*</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>အီလက်ထရွန်နစ်လိုင်းများမှတဆင့် ငွေထုတ်စက် သို့မဟုတ် မိုဘိုင်းဘဏ် အက်ပလီကေးရှင်း</td>
          <td>၅ ဘတ်/ လုပ်ငန်းစဉ်တစ်ခုလျှင်</td>
        </tr>
        <tr>
          <td>ဘဏ်ကောင်တာမှတဆင့်</td>
          <td>၁၅-၃ဝ ဘတ်/ လုပ်ငန်းစဉ်တစ်ခုလျှင်</td>
        </tr>
        <tr>
          <td>တိုက်ရိုက်ငွေလွဲခြင်းမှတဆင့်</td>
          <td>၁ဝ-၃ဝ ဘတ်/ လုပ်ငန်းစဉ်တစ်ခုလျှင်</td>
        </tr>
        <tr>
          <td>ဆင်ပိတန်ရုံးခွဲတွင် တိုက်ရိုက်ငွေသွင်းခြင်း</td>
          <td>အခကြေးငွေပေးရန်မလိုပါ</td>
        </tr>
      </tbody>
    </table>
    <br />
    <p>
      မှတ်ချက် : -* မည်သည့်နေရာမှတဆင့်ငွေသွင်းမည်ကို ငွေပေးသွင်းမည့်သူမှသာ ဆုံးဖြတ်ရမည်ဖြစ်သည်။ အဆိုပါအခကြေးငွေများသည်
      ဘဏ် သို့မဟုတ် ဝန်ဆောင်မှုပေးထားသည့် နေရာမှ ကောက်ခံမှုအတိုင်း အပြောင်းအလဲရှိနိုင်ပါသည်။
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>ကျွန်ုပ်၏ချေးငွေနှင့် ငွေပြန်လည်ပေးချေမှုကို ဘယ်မှာစစ်ဆေးလို့ရပါသနည်း။</strong>
    </p>
    <p>
      -သင်၏ဖုန်းနံပါတ်နှင့်ပင်ကုဒ်ကို အသုံးပြု၍ Syn Pitarn အက်ပလီကေးရှင်းထဲဝင်ပြီး ချေးငွေနှင့် ပြန်လည်ပေးဆပ်ရမည့်
      အချိန်ဇယားများကို ကြည့်ရှုနိုင်ပါသည်။
    </p>
    <p>
      -သင်ရွေးချယ်ထားသော လူမူကွန်ယက်မှတဆင့် ပေးဆပ်ရမည့် အချိန် သတိပေးချက်၊ ပေးချေမှုလက်ခံရရှိခြင်း၊
      နောက်ကျပြီးမှပေးချေမှုစသည်တို့ကို ကျွန်ုပ်တို့မှ အကြောင်းကြားစာများပေးပို့ပါမည်။
    </p>
    <br />
    <p><strong>#</strong> <strong>လက်ခံပြေစာ</strong></p>
    <p>
      -သင်၏ ချေးငွေပြန်လည်ပေးဆပ်သော ငွေစာရင်းကို ကျွန်တော်တို့ဘက်မှလက်ခံရရှိခဲ့လျှင် Syn Pitarn အက်ပလီကေးရှင်းထဲရှိ
      သင်၏သီးသန့် အကောင့်ထဲသို့ ကျွန်တော်တို့မှ သင်၏ငွေပေးသွင်းမှု လက်ခံရရှိကြောင်း လက်ခံပြေစာကို တင်ပြထားပေးမည်ဖြစ်သည်။
      ထို့နောက် သင်မှ သင့်အကောင့်ထဲသို့အချိန်မရွေး ဝင်ရောက်စစ်ဆေးနိုင်မည်ဖြစ်သည်။
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>အကယ်၍ ကျွန်ုပ်က သတ်မှတ်ထားသော ပမာဏထက်လျော့နည်း၍ ပေးချေပါက ဘာများဖြစ်နိုင်ပါသနည်း။</strong>
    </p>
    <p>
      -ကျန်ရှိနေသော ပမာဏအပေါ်မူတည်၍ အတိုးနှုန်းကို တွက်ချက်မည်ဖြစ်သည်။ ဆိုလိုရင်းမှာ အကယ်၍ သတ်မှတ်ထားသော
      ပမာဏထက်လျော့နည်း၍ ပေးဆပ်ပါက အတိုးပိုပေးရမည်ဖြစ်သည်။
    </p>
    <p>-ထို့အပြင် ခြားနားချက်ပမာဏကို သင်၏နောက်ထပ်သတ်မှတ်ထားသောငွေသွင်းရမည့်ပမာဏတွင် ထည့်ပေါင်းမည်ဖြစ်သည်။</p>
    <p>
      -သတ်မှတ်ထားသောပမာဏထက်လျော့ပေးခြင်းက သင်၏ချေးငွေမှတ်တမ်းကို ထိခိုက်နိုင်သည့်အပြင် နောင်အနာဂတ်တွင်
      ချေးငွေအသစ်ရရှိနိုင်ခြေလျော့ကျစေနိုင်ပါသည်။
    </p>
    <br />
    <p>
      <strong>#</strong>
      <strong>သတ်မှတ်အချိန်ကာလထက်စောပြီး ပြန်ပေးလို့ရနိုင်ပါသလား။</strong>
    </p>
    <p>-ရပါတယ်။ အကယ်၍ သင်က သတ်မှတ်ကာလထက်စော၍ ပြန်ပေးပါက ပြန်ပေးခဲ့သော ချေးငွေအရင်းအပေါ် အတိုးပေးရန်မလိုတော့ပါ။</p>
    <br />
    <p>
      <strong>#</strong>
      <strong>အကယ်၍ ကျွန်ုပ်ကို ချေးငွေပြန်မဆပ်နိုင်ခဲ့ရင် ဘာတွေ ဖြစ်နိုင်သလဲ။</strong>
    </p>
    <p>
      -အကယ်၍ သင့်ထံမှပြန်လည်ပေးချေဖို့ ပြဿနာရှိနေခဲ့မယ်ဆိုရင် ကျွန်ုပ်တို့ကို အမြန်ဆုံးဆက်သွယ်ရမည် ဖြစ်သည်။
      <span class="text-primary cursor-pointer" @click="scroll('footer')">(ဤနေရာတွင် နှိပ်ပါ)</span>
    </p>
    <p>-ကျွန်ုပ်တို့ ချေးငွေအရာရှိမှ ပြန်လည်ပေးဆပ်ခြင်းဆိုင်ရာအချက်များကို ပြန်လည်ပြင်ဆင်ပြီး ကူညီပေးပါလိမ့်မည်။</p>
    <p>
      -သင့်ပြဿနာကို ကျွန်ုပ်တို့အား စောစော ပြောပြခြင်းဖြင့် သင့်ကို ကူညီပေးရန်နှင့် သင်၏ချေးငွေမှတ်တမ်းကို
      ထိခိုက်သက်ရောက်မှုမရှိနိုင်ပေ။
    </p>
    <br />
    <p>
      <strong>
        <span style="text-decoration: underline">လုံခြုံရေး</span>
      </strong>
    </p>
    <p><strong>ကျွန်ုပ်၏အချက်အလက်များ လုံခြုံစိတ်ချမှုရှိပါသလား ။</strong></p>
    <p>-ရှိပါသည်။ ကျွန်ုပ်တို့က အစိုးရမှ သတ်မှတ်ထားသော အချက်အလက်လုံခြုံရေးဆိုင်ရာ စည်းမျဉ်းများကို လိုက်နာပါသည်။</p>
    <p>&nbsp;</p>
    <p>
      <strong>
        <span style="text-decoration: underline">အခြားအချက်များ</span>
      </strong>
    </p>
    <p>
      <strong>အကယ်၍ ကျွန်ုပ် လက်ကိုင်ဖုန်းပြောင်းခဲ့ပါက ဘာဖြစ်နိုင်ပါသလဲ ။</strong>
    </p>
    <p>
      -အကယ်၍ သင်၏ဖုန်းနံပါတ်ပြောင်းမည်ဆိုပါက သင့်အကောင့်ထဲဝင်၍ ဖုန်းနံပါတ်ပြောင်းလဲခြင်းကို ကျွန်ုပ်တို့အား
      အသိပေးအကြောင်းကြားရမည်ဖြစ်သည်။
    </p>
    <p><strong>&nbsp;</strong></p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  created() {
    const refName = this.$route.params.refName
    if (refName) {
      this.$nextTick(() => {
        this.scroll(refName)
      })
    }
  },
  methods: {
    goToBranch() {
      this.$router.push({
        name: 'landing',
        params: {
          refName: 'branch',
        },
      })
    },
    goToMessenger() {
      window.open('http://m.me/105351764988706', '_blank')
    },
    goToFackbook() {
      window.open('https://www.facebook.com/', '_blank')
    },
    goToViber() {
      window.open('https://www.viber.com/', '_blank')
    },
    goToLine() {
      window.open('https://line.me/', '_blank')
    },
    goToRegister() {
      this.$router.push({
        name: 'register',
      })
    },
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style></style>
