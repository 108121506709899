<template>
  <div class="text-justify">
    <p>คำถามที่พบบ่อย</p>
    <p><strong>สินพิธาน</strong></p>
    <p><strong>สินพิธานคือใคร</strong></p>
    <ul>
      <li>
        สินพิธานเป็นบริษัทที่ให้บริการทางการเงินสัญชาติไทยที่เน้นการรวมบริการทางการเงินสำหรับแรงงานที่มีรายได้น้อยโดยเฉพาะแรงงานข้ามชาติในประเทศไทย
      </li>
      <li>บริการหลักของเราคือการให้สินเชื่อ</li>
      <li>เริ่มดำเนินการในปทุมธานีแล้ว และจะขยายการดำเนินงานให้ครอบคลุมจังหวัดอื่นๆ ในอีกไม่กี่เดือนข้างหน้า</li>
    </ul>
    <p><strong>มีใบอนุญาต / ถูกกฎหมายหรือไม่</strong><strong>? </strong></p>
    <p>สินพิธานมีใบอนุญาตถูกต้องตามกฎหมายและกำกับดูแลโดยกระทรวงการคลังภายใต้ใบอนุญาตพิโก้ ไฟแนนซ์</p>
    <p><strong>ผลประโยชน์ที่คุณจะได้รับจากการกู้ยืมเงินจากสินพิธาน</strong></p>
    <p>&uuml;&nbsp; เราอยู่ภายใต้การกำกับดูแล</p>
    <p>&uuml;&nbsp; เรามีความโปร่งใส</p>
    <p>&uuml;&nbsp; เรามีการแปลทุกอย่างเป็นภาษาที่ผู้กู้ถนัด</p>
    <p>&uuml;&nbsp; อัตราดอกเบี้ยของเรากำหนดโดยรัฐในอัตราร้อยละ 3 ต่อเดือน</p>
    <p>
      &uuml;&nbsp; หากคุณมีประวัติการชำระคืนที่ดี เราจะพิจารณาลดอัตราเหล่านี้
      <span class="text-primary cursor-pointer" @click="scroll('lower-rate')">
        [คลิกเพื่อดูคำถามที่พบบ่อยที่เกี่ยวข้อง]
      </span>
    </p>
    <p>&uuml;&nbsp; เราไม่มีค่าใช้จ่ายแอบแฝงหรือค่าธรรมเนียม</p>
    <p>
      &uuml;&nbsp; คุณจะได้รับความสะดวก โดยจะต้องเข้ามาที่สาขาของเราเพียงครั้งเดียว
      นอกจากนั้นบริการส่วนใหญ่ของเราสามารถเข้าถึงทางออนไลน์ได้ตลอดเวลา
    </p>
    <p>
      <br />
      <strong>มีค่าใช้จ่ายอะไรหรือไม่ก่อนที่จะได้รับเงินกู้ </strong>
    </p>
    <ul>
      <li>ไม่มีค่าใช้จ่ายอะไรเลยก่อนที่จะได้รับเงินกู้</li>
      <li>เราไม่มีค่าใช้จ่ายแอบแฝง</li>
      <li>
        เราไม่มีนโยบายการใช้ตัวแทนในการพูดคุยกับลูกค้า ดังนั้นอย่าหลงเชื่อจ่ายค่าธรรมเนียมใดๆ
        หากมีผู้อ้างว่าสามารถช่วยคุณให้ได้เงินกู้จากสินพิธานได้ &nbsp;
      </li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>สินพิธานเราออกแบบมาเพื่อให้ชีวิตคุณง่ายขึ้น</strong></p>
    <p><strong>ยินดีด้วย.</strong></p>
    <ul>
      <li>
        การใช้บริการของเราถือว่าคุณได้รับประโยชน์จากเทคโนโลยีทางการเงิน
        โดยคุณสามารถใช้โทรศัพท์มือถือของคุณเพื่อสื่อสารกับเราในเวลาที่คุณสะดวก
      </li>
      <li>
        คุณสามารถเข้าถึงข้อมูลเกี่ยวกับสถานะเงินกู้และกำหนดการชำระเงินของคุณ
        หรือการยื่นขอเงินกู้ใหม่ผ่านทางโทรศัพท์มือถือของคุณ
      </li>
    </ul>
    <p><strong>เวลาทำการ</strong></p>
    <p>เราเปิดให้บริการในช่วงเย็นและวันเสาร์- อาทิตย์</p>
    <p><strong>จะติดต่อ สินพิธาน ได้อย่างไร</strong></p>
    <ul>
      <li>
        โปรดติดต่อผ่าน [messenger]
        <span class="text-primary cursor-pointer" @click="goToMessenger">[คลิกที่นี่]</span>
      </li>
      <li>
        หรือมาที่สาขาของเราได้ที่<span class="text-primary cursor-pointer" @click="goToBranch">
          [คลิกเพื่อไปยังแผนที่]</span
        >
      </li>
    </ul>
    <p><strong>&nbsp;</strong></p>
    <p><strong>เงินกู้</strong></p>
    <p><strong>เงื่อนไขในการได้รับเงินกู้ คุณต้อง</strong><strong>;</strong></p>
    <ul>
      <li>มีถิ่นที่อยู่หรือทำงานในปทุมธานี</li>
      <li>มีอายุไม่ต่ำกว่า 20 ปี</li>
      <li>เคยทำงานในประเทศไทยมาแล้วอย่างน้อย 2 ปี และทำงานกับนายจ้างเดิมในประเทศไทยมาแล้วอย่างน้อย 1 ปี</li>
      <li>
        มีใบอนุญาตทำงานที่ถูกต้อง (บัตรสีชมพู/MOU) และเหลืออายุการทำงานอย่างน้อย 3
        เดือนหลังจากวันชำระคืนเงินกู้ครั้งสุดท้ายของคุณ
      </li>
      <li>มีบัญชีธนาคารในประเทศไทย</li>
      <li>มีบัญชีโซเชียลมีเดียอันใดอันหนึ่งต่อไปนี้ Facebook Messenger, Line หรือ Viber</li>
    </ul>
    <p><strong>กระบวนการขอเงินกู้ใช้เวลานานเท่าใด</strong></p>
    <ul>
      <li>
        เมื่อคุณส่งแบบฟอร์มขอสินเชื่อผ่านโทรศัพท์มือถือของคุณแล้ว
        คุณจะถูกขอให้เลือกวันและเวลาสำหรับการสัมภาษณ์ทางวิดีโอกับเจ้าหน้าที่สินเชื่อ (ที่พูดภาษาของคุณถนัด) ของเรา
        โดยปกติจะใช้เวลา 3-5 วันหลังจากส่งแบบฟอร์ม
      </li>
      <li>
        หลังจากสัมภาษณ์ผ่านวิดีโอกับเจ้าหน้าที่สินเชื่อของเราแล้ว หากสินเชื่อได้รับการอนุมัติ
        คุณจะได้รับข้อความยืนยันการอนุมัติสินเชื่อเบื้องต้น โดยคุณจะต้องไปที่สาขาของเราเพื่อยืนยันตัวตน
        ซึ่งจะต้องทำเพียงครั้งเดียวเท่านั้น
      </li>
      <li>หลังจากนั้นเราจะโอนเงินกู้ให้คุณภายในวันเดียวกัน</li>
    </ul>
    <p><strong>ฉันต้องไปที่สาขาหรือไม่</strong></p>
    <ul>
      <li>ใช่ เพราะเราต้องตรวจสอบว่าคุณเป็นบุคคลเดียวกันกับข้อมูลในเอกสารของคุณ</li>
      <li>โดยคุณต้องไปที่สาขาเพียงครั้งเดียวเพื่อยืนยันตัวตน หลังจากนั้นทุกรายการสามารถทำผ่านโทรศัพท์มือถือของคุณ</li>
    </ul>
    <p><strong>ฉันสามารถกู้เงินได้เท่าไหร่</strong></p>
    <ul>
      <li>สินพิธานให้เงินกู้ครั้งแรกในวงเงินสูงสุด 5,000 บาท</li>
      <li>อย่างไรก็ตาม หากคุณมีประวัติการชำระคืนที่ดี เราสามารถพิจารณาเพิ่มวงเงินได้</li>
      <li>โดยสินพิธานให้วงเงินกู้สูงสุดได้ถึง 50,000 บาท</li>
    </ul>
    <p><strong>สินพิธานให้ดอกเบี้ยเท่าไหร่</strong></p>
    <ul>
      <li>อัตราดอกเบี้ยของเรากำหนดโดยรัฐในอัตราร้อยละ 3 ต่อเดือน</li>
      <li>
        ดอกเบี้ยของเราคำนวณแบบอัตราดอกเบี้ยแบบลดต้นลดดอก ตามวิธีอัตราดอกเบี้ยที่แท้จริง
        ซึ่งหมายความว่าดอกเบี้ยจะคำนวณตามยอดเงินกู้คงค้าง
      </li>
    </ul>
    <p><strong>&ldquo;ประวัติเครดิต&rdquo; คืออะไร</strong></p>
    <ul>
      <li>ประวัติเครดิตคือรายการที่บันทึกประวัติพฤติกรรมการชำระคืนเงินกู้ของคุณ</li>
      <li>การชำระคืนเงินกู้ตามจำนวนและเวลาที่กำหนดไว้อย่างสม่ำเสมอ จะทำให้คุณจะมีประวัติเครดิตที่ดี</li>
    </ul>

    <p>&nbsp;</p>
    <p id="lower-rate">
      <strong>สามารถขออัตราดอกเบี้ยที่ที่ถูกลงได้หรือไม่</strong>
    </p>
    <p>ได้แน่นอน หากคุณมีประวัติเครดิตที่ดีหลังจากมีเงินกู้กับสินพิธาน 2 รอบขึ้นไป เราจะพิจารณาลดอัตราดอกเบี้ยให้คุณ</p>
    <p>&nbsp;</p>
    <p><strong>ระยะเวลากู้ยืม</strong></p>
    <ul>
      <li>เงินกู้ก้อนแรกของคุณมีระยะเวลา 2-3 เดือน ผ่อนชำระ 4 งวดทุกๆ 2 สัปดาห์</li>
      <li>
        หลังจากการกู้ยืมครั้งแรกของคุณและถ้าคุณมีประวัติเครดิตที่ดี
        เรายินดีพิจารณาเงินกู้ก้อนใหญ่ขึ้นและให้ระยะเวลาที่ยาวขึ้นได้ถึง 6 เดือน
      </li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>ความถี่ในการชำระคืนเงินกู้</strong></p>
    <p>โดยปกติทุก 2 สัปดาห์ แต่สำหรับลูกค้าที่ประวัติเครดิตดี เราสามารถพิจารณาการชำระคืนเป็นรายเดือน</p>
    <p>&nbsp;</p>
    <p><strong>มีค่าปรับสำหรับการชำระล่าช้าหรือไม่</strong></p>
    <ul>
      <li>หากชำระคืนล่าช้ากว่ากำหนด 5 วัน ขอสงวนสิทธิ์ในการเรียกเก็บค่าทวงถามเดือนละ 50 บาท</li>
      <li>การชำระคืนล่าช้าจะส่งผลต่อประวัติเครดิตของคุณและความสามารถในการขอกู้ในอนาคตของคุณ</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>สามารถขอเงินกู้มากกว่าหนึ่งก้อนได้หรือไม่</strong></p>
    <ul>
      <li>
        ไม่ได้ คุณสามารถมีเงินกู้ได้หนึ่งก้อนต่อระยะเวลาเท่านั้น อย่างไรก็ตาม
        หลังจากที่คุณได้ชำระคืนเงินกู้ก้อนก่อนหน้าแล้ว คุณสามารถขอเงินกู้ก้อนใหม่ได้
      </li>
      <li>
        สำหรับเงินกู้ก้อนต่อไป คุณไม่จำเป็นต้องไปที่สาขาของเรา เว้นแต่คุณเว้นระยะเงินกู้ครั้งล่าสุดกับเรามากกว่า 6&nbsp;
        เดือน
      </li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>ฉันรับเงินกู้ได้ที่ไหน</strong></p>
    <p>เราโอนเงินกู้เข้าบัญชีธนาคารของคุณโดยตรงเสมอ</p>
    <p>&nbsp;</p>
    <p>
      <strong>เงินกู้สามารถโอนเข้าบัญชีธนาคารในชื่ออื่นนอกจากชื่อตามบัตรประชาชนหรือใบอนุญาตทำงานได้หรือไม่</strong>
    </p>
    <p>ไม่ เราจะโอนเงินไปยังบัญชีธนาคารที่มีชื่อเดียวกับเอกสารยืนยันตัวตนของคุณเท่านั้น</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p><strong>&nbsp;</strong></p>
    <p>&nbsp;</p>
    <p><strong>ขั้นตอนการสมัครขอเงินกู้</strong></p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>ฉันจะสมัครขอเงินกู้ได้อย่างไร</strong></p>
    <p>
      สามารถเริ่มต้นด้วยการกรอกแบบฟอร์มใบสมัครขอเงินกู้
      <span class="text-primary cursor-pointer" @click="goToRegister">[คลิกที่นี่]</span>
    </p>
    <pre>&nbsp;</pre>
    <p><strong>ต้องยื่นเอกสารอะไรบ้าง</strong></p>
    <p>ต้องการเอกสารเพียง 4 รายการอัพโหลดจากโทรศัพท์มือถือที่ใช้สมัคร</p>
    <pre>
1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; เซลฟี่ รูปหน้าที่ชัดเจน ห้ามสวมหมวกหรือแว่นกันแดด ควรใช้พื้นหลังสีขาว</pre
    >
    <pre>
2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ใบอนุญาตทำงานในไทยหรือบัตรสีชมพูของคุณ (ทั้งสองด้าน)</pre
    >
    <pre>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; บัตรประชาชนหรือหนังสือเดินทางของคุณ</pre>
    <pre>
4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; รูปภาพสลิปเงินเดือนล่าสุดของคุณหรือสำเนาใบแจ้งยอดธนาคารหรือสมุดบัญชีธนาคารที่แสดงการรับเงินเดือนล่าสุดของคุณ</pre
    >
    <pre>เราขอแนะนำให้คุณเตรียมเอกสารเหล่านี้ก่อนกรอกแบบฟอร์มใบสมัคร</pre>
    <pre>&nbsp;</pre>
    <p><strong>จำเป็นต้องมีหลักประกันในการกู้หรือไม่</strong><strong>?</strong></p>
    <p>ไม่ต้องการหลักประกันใด ๆ</p>
    <p><strong>&nbsp;</strong></p>
    <p>
      <strong>ถ้ามีหลักประกัน จะได้รับเงินกู้ง่ายขึ้นหรือสามารถรับวงเงินกู้เพิ่มเติมได้หรือไม่</strong>
    </p>
    <p>ในอนาคตเราจะเสนอสินเชื่อที่มีหลักประกันซึ่งมีอัตราดอกเบี้ยที่ต่ำลง</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>หากมีชื่อในแบลคลิสต์ จะมีสิทธิ์สมัครขอสินเชื่อของคุณหรือไม่</strong><strong>?</strong></p>
    <p>ถ้าเครดิตบูโรแสดงเครดิตไม่ดี เราไม่สามารถให้กู้ได้</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>ถ้าไม่มีใบอนุญาตทำงานในไทย สามารถกู้ได้หรือไม่</strong></p>
    <ul>
      <li>ไม่ได้ ต้องมีใบอนุญาตทำงานในไทยหรือบัตรสีชมพูเพื่อเป็นหลักฐานประกอบการกู้</li>
      <li>
        หากคุณมีงานทำอยู่ แต่ไม่มีใบอนุญาตทำงานในไทยหรือบัตรสีชมพู โปรด
        <span class="text-primary cursor-pointer" @click="scroll('footer')"> [คลิกที่นี่] </span>
      </li>
    </ul>
    <pre><strong>สามารถอัพเดตข้อมูลแบบฟอร์มใบสมัครหลังจากที่ส่งใบสมัครแล้วได้หรือไม่</strong></pre>
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; แบบฟอร์มผู้สมัครที่ส่งมาแล้วจะถูกล็อคสำหรับการแก้ไขหลังจากส่งแล้ว โปรดแจ้งเจ้าหน้าที่สินเชื่อของเราในระหว่างการสัมภาษณ์ หากคุณต้องการเปลี่ยนแปลงอะไร</pre
    >
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; หลังจากเงินกู้ถูกอนุมัติแล้ว คุณสามารถแก้ไขข้อมูลส่วนตัวของคุณได้ โดยไปที่เมนู 'โปรไฟล์ของฉัน' ในหน้าเว็บไซต์หรือแอพพลิเคชั่น</pre
    >
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; คุณยังสามารถติดต่อเจ้าหน้าที่ของเราผ่านทาง <span class="text-primary cursor-pointer" @click="scroll('footer')">[Link for contact channel]</span> หากคุณมีคำถามเพิ่มเติม</pre>
    <pre>&nbsp;</pre>
    <pre><strong>ต้องเข้าไปที่สาขาของสินพิธานหรือไม่</strong></pre>
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; เราจำเป็นต้องยืนยันตัวตนของคุณสำหรับเงินกู้ครั้งแรก เมื่อได้รับการเตือนว่าเงินกู้อนุมัติแล้ว เราขอให้คุณเข้ามาที่สาขาของเราเพื่อให้กระบวนการตรวจสอบเสร็จสิ้น</pre
    >
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; สำหรับเงินกู้ก้อนถัดไป เราจะสื่อสารกับคุณผ่านโทรศัพท์มือถือของคุณและดำเนินการตรวจสอบทั้งหมดทางอิเล็กทรอนิกส์</pre
    >
    <pre>&nbsp;</pre>
    <pre><strong>สามารถบันทึกแบบฟอร์มใบสมัครไว้และกรอกให้เสร็จภายหลังได้หรือไม่</strong></pre>
    <pre>
สามารถทำได้ โดยการกดปุ่ม &ldquo;ถัดไป&rdquo; ที่ด้านล่างของแบบฟอร์มแต่ละหน้าของการสมัครจะเป็นการบันทึกข้อมูลของหน้านั้นๆ คุณสามารถเข้าสู่ระบบได้ตลอดเวลาเพื่อดำเนินการให้เสร็จสิ้น</pre
    >
    <pre>&nbsp;</pre>
    <pre><strong>ฉันจำเป็นต้องมีบัญชีโซเชียลมีเดียเพื่อขอสินเชื่อหรือไม่</strong></pre>
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; จำเป็นต้องมี เราใช้ Facebook Messenger, Viber หรือ Line เพื่อติดต่อคุณ</pre
    >
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; หากคุณต้องการตั้งค่าบัญชีโซเชียลมีเดีย โปรดคลิกแพลตฟอร์มที่คุณต้องการด้านล่าง</pre
    >
    <pre>
-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="text-primary cursor-pointer" @click="goToFackbook">Facebook Messenger</span></pre>
    <pre>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="text-primary cursor-pointer" @click="goToViber"> Viber</span></pre>
    <pre>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="text-primary cursor-pointer" @click="goToLine">LINE</span></pre>
    <pre>&nbsp;</pre>
    <pre><strong>ต้องมีรหัสผ่านหรือไม่</strong></pre>
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ต้องมี เมื่อลงทะเบียนกับสินพิธานเป็นครั้งแรก คุณจะถูกขอให้ตั้งค่าบัญชีส่วนตัวของคุณ</pre
    >
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; หมายเลขโทรศัพท์มือถือของคุณคือชื่อล็อกอิน/ Username ของคุณ</pre
    >
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; คุณจะต้องตั้งรหัสเป็นตัวเลขจำนวน 6 หลัก (Pincode) ซึ่งเป็นชุดตัวเลขที่คุณจำได้หรือถูกจดไว้ในที่ปลอดภัย</pre
    >
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; คุณต้องใช้รหัส (Pincode) นี้เพื่อเข้าถึงข้อมูลส่วนตัวของคุณในเว็บไซต์หรือแอพพลิเคชั่นของเรา</pre
    >
    <pre>&nbsp;</pre>
    <pre><strong>ตรวจสอบสถานะการขอสินเชื่อได้จากไหน</strong></pre>
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; คุณสามารถ เข้าสู่ระบบ ผ่านเว็บไซต์หรือแอพพลิเคชั่นของสินพิธานโดยใช้หมายเลขโทรศัพท์และรหัส (Pincode) ของคุณเพื่อตรวจสอบสถานะล่าสุดได้ตลอดเวลา</pre
    >
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; เราจะส่งการแจ้งเตือนถึงคุณผ่านช่องทางโซเชียลมีเดียที่คุณเลือกเมื่อเราจำเป็นต้องแจ้งข้อมูลแก่คุณได้แก่ สถานะการตรวจสอบใบสมัครและยืนยันวันที่สัมภาษณ์, การอนุมัติสินเชื่อ, การยืนยันวันเข้ามาที่สาขา (สำหรับเงินกู้ครั้งแรกเท่านั้น) หรือข่าวคราวอื่นๆ</pre
    >
    <pre>&nbsp;</pre>
    <pre><strong>มีเจ้าหน้าที่ในการติดต่อสอบถามหรือไม่</strong></pre>
    <pre>สามารถติดต่อเราผ่านไอคอนแชท Facebook Messenger บนเว็บไซต์หรือแอพพลิเคชั่นของเรา</pre>
    <pre>&nbsp;</pre>
    <pre>&nbsp;</pre>
    <pre><strong>มีค่าใช้จ่ายใด ๆ ระหว่างขั้นตอนการสมัครเงินกู้จนก่อนได้รับเงินกู้หรือไม่</strong></pre>
    <pre>
ไม่มีค่าใช้จ่าย สินพิธานจะโอนเงินกู้ที่ได้รับอนุมัติเต็มจำนวน อย่างไรก็ตาม คุณอาจถูกเรียกเก็บค่าธรรมเนียมธนาคารจากธนาคารของคุณ</pre
    >
    <pre>&nbsp;</pre>
    <pre><strong>เหตุผลที่เงินกู้ไม่ได้รับการอนุมัติ</strong></pre>
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ทำงานหรืออาศัยอยู่ในจังหวัดที่เราไม่ได้ดำเนินกิจการ หรือทำงานให้กับบริษัทปัจจุบันในประเทศไทยน้อยกว่าหนึ่งปี หรืออายุน้อยกว่า 20 ปี</pre
    >
    <pre>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; เอกสารไม่สมบูรณ์</pre>
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ประวัติเครดิตที่ไม่ดีในการชำระคืนเงินกู้ก้อนห่อนหน้านี้</pre
    >
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; วันหมดอายุใบอนุญาตทำงานในไทยของคุณเหลือน้อยกว่า 3 เดือน</pre
    >
    <pre>คุณยังสามารถสมัครขอกู้ใหม่ได้ภายใน 6 เดือนหากสถานการณ์ข้างต้นของคุณเปลี่ยนไป</pre>
    <pre>โปรดติดต่อ <span class="text-primary cursor-pointer" @click="scroll('footer')"
        >[Link for contact channel]</span> หากมีคำถามเพิ่มเติม</pre>
    <pre>&nbsp;</pre>
    <pre><strong>การชำระคืนเงินกู้</strong></pre>
    <pre>&nbsp;</pre>
    <pre><strong>สามารถชำระหนี้ได้อย่างไร</strong></pre>
    <pre>คุณสามารถชำระเงินได้ที่</pre>
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ตู้ ATM ของธนาคารที่คุณมีบัญชีอยู่ [คลิกที่นี่เพื่อดูขั้นตอน]</pre
    >
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ผ่านแอพพลิเคชั่นธนาคารบนมือถือ [คลิกที่นี่เพื่อดูขั้นตอน]</pre
    >
    <pre>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; เคาน์เตอร์ธนาคารที่คุณมีบัญชีอยู่</pre>
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; หักจากบัญชีธนาคารของคุณโดยตรง [คลิกที่นี่เพื่อดูขั้นตอน]</pre
    >
    <pre>&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; จ่ายที่สำนักงานของสินพิธาน</pre>
    <pre>สามารถชำระแทนบุคคลอื่นในนามของฉัน เช่น สมาชิกในครอบครัวหรือเพื่อน</pre>
    <pre>&nbsp;</pre>
    <pre><strong>บุคคลอื่นสามารถชำระเงินแทนได้หรือไม่</strong></pre>
    <pre>
สามารถทำได้ โดยบุคคลนั้นต้องจ่ายผ่านบาร์โค้ดบนใบแจ้งหนี้ของคุณหากชำระด้วย ATM หรือแอพพลิเคชั่นธนาคารหรือเข้ามาจ่ายที่สำนักงานสาขาของสินพิธาน</pre
    >
    <pre>&nbsp;</pre>
    <pre><strong>มีค่าใช้จ่ายหรือค่าธรรมเนียมอะไรบ้าง</strong></pre>
    <pre><strong>&nbsp;</strong></pre>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th colspan="2" scope="col">ค่าใช้จ่ายที่ใช้ในการชำระเงิน*</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>ชำระผ่านช่องทางอิเลคทรอนิก ได้แก่ ตู้ ATM และ &nbsp;แอพพลิเคชั่นบนมือถือ (Mobile Banking)</td>
          <td>5 บาท/ครั้ง</td>
        </tr>
        <tr>
          <td>ชำระที่เคาน์เตอร์ของธนาคาร</td>
          <td>15-30 Baht/transaction</td>
        </tr>
        <tr>
          <td>ชำระโดยหักบัญชีธนาคาร</td>
          <td>10-30 บาท/ครั้ง</td>
        </tr>
        <tr>
          <td>ชำระที่สำนักงานของสินพิธาน</td>
          <td>ไม่มีค่าธรรมเนียม</td>
        </tr>
      </tbody>
    </table>
    <p>
      หมายเหตุ: -* ค่าใช้จ่ายที่ใช้ในการชำระเงิน
      หน่วยงานภายนอกนั้นๆจะเป็นผู้กำหนดและค่าธรรมเนียมดังกล่าวอาจมีการเปลี่ยนแปลงตามที่เรียกเก็บจริงโดยผู้ให้บริการรับชำระเงิน
    </p>
    <pre>&nbsp;</pre>
    <pre><strong>ตรวจสอบสถานะเงินกู้และการชำระคืนได้จากไหน</strong></pre>
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; เข้าสู่ระบบผ่านเว็บไซต์หรือแอพพลิเคชั่นของสินพิธานโดยใช้หมายเลขโทรศัพท์และรหัส (Pincode) เพื่อตรวจสอบสถานะเงินกู้และกำหนดการชำระเงิน</pre
    >
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; เราจะส่งการแจ้งเตือนถึงคุณผ่านช่องทางโซเชียลมีเดียที่คุณเลือกเมื่อเราจำเป็นต้องแจ้งข้อมูลแก่คุณได้แก่ สถานะการตรวจสอบใบสมัครและยืนยันวันที่สัมภาษณ์, การอนุมัติสินเชื่อ, การยืนยันวันเข้ามาที่สาขา (สำหรับเงินกู้ครั้งแรกเท่านั้น) หรือข่าวคราวอื่นๆ</pre
    >
    <pre>&nbsp;</pre>
    <pre><strong>ใบเสร็จรับเงิน</strong></pre>
    <pre>
เมื่อเราได้รับข้อมูลการชำระเงินของคุณแล้ว ระบบจะสร้างและอัพโหลดใบเสร็จไปยังหน้าบัญชีส่วนตัวของคุณในเว็บไซต์หรือแอพพลิเคชั่นของสินพิธาน คุณสามารถเข้าสู่ระบบของคุณเพื่อตรวจสอบได้ตลอดเวลา</pre
    >
    <pre>&nbsp;</pre>
    <pre><strong>หากชำระคืนน้อยกว่าจำนวนที่กำหนดไว้ จะเป็นอย่างไร</strong></pre>
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ดอกเบี้ยคำนวณจากยอดค้างชำระ หมายความว่า หากชำระคืนน้อยกว่าจำนวนที่กำหนด จะต้องเสียดอกเบี้ยเพิ่ม</pre
    >
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ยิ่งไปกว่านั้น ส่วนต่างจะถูกเพิ่มไปยังจำนวนเงินที่กำหนดไว้ในครั้งต่อไปของคุณ</pre
    >
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; การจ่ายเงินน้อยกว่าที่กำหนดจะส่งผลต่อประวัติเครดิตของคุณ และลดโอกาสที่จะได้รับเงินกู้ก้อนใหม่ในอนาคต</pre
    >
    <pre>&nbsp;</pre>
    <pre><strong>ฉันสามารถชำระคืนเร็วกว่ากำหนดได้หรือไม่</strong></pre>
    <pre>แน่นอน หากคุณชำระคืนเร็วกว่ากำหนดคุณจะไม่ต้องจ่ายดอกเบี้ยของเงินต้นตามจำนวนวันที่ชำระคืนก่อนกำหนด</pre>
    <pre>&nbsp;</pre>
    <pre><strong>หากไม่สามารถชำระคืนเงินกู้ได้จะเป็นอย่างไร</strong></pre>
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; หากคุณมีปัญหาในการชำระคืน คุณต้องติดต่อเราทันที <span
                class="text-primary cursor-pointer"
                @click="scroll('footer')"
                >[คลิกที่นี่]</span> และเจ้าหน้าที่สินเชื่อของเราจะช่วยคุณโดยการปรับโครงสร้างเงื่อนไขการชำระเงินให้คุณ</pre>
    <pre>
&middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; การแจ้งปัญหาของคุณให้เราทราบล่วงหน้า ทำให้เราสามารถช่วยคุณได้ และประวัติเครดิตของคุณอาจไม่ได้รับผลกระทบ</pre
    >
    <pre>&nbsp;</pre>
    <pre>&nbsp;</pre>
    <pre>&nbsp;</pre>
    <pre><strong>ความปลอดภัย</strong></pre>
    <pre><strong>ข้อมูลมีความปลอดภัยหรือไม่</strong></pre>
    <pre>แน่นอน เราปฏิบัติตามข้อบังคับและกฎหมายเกี่ยวกับความเป็นส่วนตัวของข้อมูล</pre>
    <pre>&nbsp;</pre>
    <pre><strong>อื่น ๆ</strong></pre>
    <pre><strong>ถ้าเปลี่ยนเบอร์โทรศัพท์ต้องทำอย่างไร</strong></pre>
    <pre>หากคุณเปลี่ยนหมายเลขโทรศัพท์ของคุณ คุณจะต้องลงชื่อเข้าใช้บัญชีของคุณและแจ้งให้เราทราบถึงการเปลี่ยนแปลง</pre>
    <pre>&nbsp;</pre>
  </div>
</template>

<script>
export default {
  created() {
    const refName = this.$route.params.refName
    if (refName) {
      this.$nextTick(() => {
        this.scroll(refName)
      })
    }
  },
  methods: {
    goToBranch() {
      this.$router.push({
        name: 'landing',
        params: {
          refName: 'branch',
        },
      })
    },
    goToMessenger() {
      window.open('http://m.me/105351764988706', '_blank')
    },
    goToFackbook() {
      window.open('https://www.facebook.com/', '_blank')
    },
    goToViber() {
      window.open('https://www.viber.com/', '_blank')
    },
    goToLine() {
      window.open('https://line.me/', '_blank')
    },
    goToRegister() {
      this.$router.push({
        name: 'register',
      })
    },
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style></style>
